import * as React from "react";
import { withPrefix } from "gatsby";
import { Helmet } from "react-helmet";
import background from "../../images/services/surveillance-magasin.jpg";
import "../../scss/style.scss";
import Banner from "../../components/Banner";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ServicesListBasic from "../../components/services/ServicesListBasic";
import ContactUs from "../../components/buttons/ContactUs";

const SurveillanceMagasin = () => {
  return (
    <main className="page">
      <title>
        Société de sécurité spécialisée dans votre protection - Services -
        Surveillance Magasin
      </title>
      <div id="wrapper" className="wrapper">
        <Header />
        <Banner
          category="Services"
          title="Surveillance Magasin"
          content=""
          background={background}
          position="left"
        />
        <div className="container pt-4 pt-md-10 pb-4 pb-md-10">
          <div className="row justify-content-start">
            <div className="col-md-8">
              <div className="service service-single">
                <div className="content">
                  <h1>
                    Service de surveillance de magasin pour une sécurité
                    maximale
                  </h1>
                  <p>
                    La sécurité de votre magasin est essentielle pour garantir
                    la protection de vos employés et de vos clients ainsi que la
                    protection de vos biens. Nos <em>agents de sécurité</em>{" "}
                    sont formés pour assurer la <em>surveillance</em> de votre
                    magasin en tout temps. Nous offrons une gamme complète de{" "}
                    <em>services de surveillance</em> pour aider à prévenir le
                    vol et les dommages, garantissant ainsi que votre entreprise
                    est en sécurité.
                  </p>
                  <h2>Notre approche de surveillance de magasin:</h2>
                  <p>
                    Nous savons que chaque magasin est unique, c'est pourquoi
                    nous offrons une approche personnalisée pour la{" "}
                    <em>surveillance de votre magasin</em>. Nous travaillons
                    avec vous pour comprendre les besoins spécifiques de votre
                    entreprise et élaborer un plan de sécurité personnalisé pour
                    répondre à ces besoins. Nos{" "}
                    <em>agents de sécurité qualifiés</em> et expérimentés sont
                    équipés des dernières technologies de surveillance pour
                    garantir une sécurité maximale de votre magasin.
                  </p>
                  <h2>Notre équipe de surveillance:</h2>
                  <p>
                    Nous sommes fiers de dire que notre équipe de{" "}
                    <em>surveillance</em> est composée d'agents de sécurité
                    hautement qualifiés et expérimentés. Tous nos agents de
                    sécurité sont formés pour faire face à toutes les situations
                    d'urgence possibles et pour agir rapidement et efficacement
                    en cas de besoin. Ils sont également équipés des dernières
                    technologies de surveillance pour garantir que votre magasin
                    est surveillé en tout temps.
                  </p>
                  <h2>Nos services de surveillance de magasin:</h2>
                  <p>
                    Nous offrons une gamme complète de{" "}
                    <em>services de surveillance de magasin</em> pour répondre à
                    vos besoins spécifiques, notamment :
                  </p>
                  <ul>
                    <li>
                      <em>Surveillance de sécurité mobile</em>
                    </li>
                    <li>
                      <em>Surveillance de sécurité statique</em>
                    </li>
                    <li>
                      <em>Surveillance de sécurité vidéo</em>
                    </li>
                    <li>
                      <em>Surveillance de sécurité discrète</em>
                    </li>
                  </ul>
                  <h2>
                    Pourquoi choisir notre service de surveillance de magasin:
                  </h2>
                  <p>
                    Nos agents sont engagés à fournir un service de qualité
                    supérieure à tous nos clients et à garantir que votre
                    entreprise soit en sécurité en tout temps. Notre approche
                    personnalisée, notre équipe de surveillance qualifiée et nos
                    technologies de pointe garantissent une sécurité maximale de
                    votre magasin.
                  </p>
                  <p>
                    Contactez-nous dès aujourd'hui pour discuter de vos besoins
                    en matière de surveillance de magasin.
                  </p>
                </div>
              </div>
              <div className="row justify-content-center pt-2">
                <ContactUs theme="black" />
              </div>
            </div>
            <div className="col-md-3 offset-md-1 pt-4">
              <h3>Nos prestations :</h3>
              <ServicesListBasic />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};
export default SurveillanceMagasin;
